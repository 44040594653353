export function convertPhoneNumber(phoneNumber) {
  return "+234" + phoneNumber.slice(1);
}

export const validateAccountName = (_, value) => {
  const regex = /^[A-Za-z\s]+$/;
  if (!regex.test(value)) {
    return Promise.reject("Account name can only contain letters and spaces");
  }
  return Promise.resolve();
};