import React, { useEffect, useState } from "react";
import AuthCard from "../../../Components/AuthCard";
import { Link, useNavigate } from "react-router-dom";
import { Form, Checkbox, Button, Input } from "antd";
import { Login, postRequest } from "../../../services";
import { LOGIN_API, REQUEST_OTP } from "../../../services/ApiConstant";
import "../CreateAccount/button.css";
import { Head } from "../../../Components/seo";

const Signin = ({ isLoggedIn, setIsLoggedIn }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const [savedEmail, setEmail] = useState();
  const [savedPassword, setPass] = useState("");
  const [error, setError] = useState("");

  const role = 2;

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  useEffect(() => {
    localStorage.setItem("userType", 2);
  }, []);

  useEffect(() => {
    setEmail(getCookie("email"));
    setPass(getCookie("password"));
  }, []);

  useEffect(() => {
    handleLoginStatusChange();
  }, [isLoggedIn]);

  const handleLoginStatusChange = () => {
    const isLoggedInValue = localStorage.getItem("token");
    setIsLoggedIn(isLoggedInValue);
  };

  const sendOtp = (email) => {
    let value = {};
    value.email = email;
    value.role = role;
    postRequest(`/email${REQUEST_OTP}`, value);
  };

  const onFinish = (values) => {
    setIsLoading(true);
    values.role = role;

    if (role === null) {
      navigate("/");
    } else {
      Login(LOGIN_API, values)
        .then((res) => {
          if (res?.data?.user?.role !== role) {
            console.log("user role: ", res.data.user.role);
            console.log("role:", role);
            // setError(`This email doesn't belongs to this user`);
            setIsLoading(false);
            setIsLoading(false);
            return;
          }
          setIsLoading(false);
          if (values.remember === true) {
            document.cookie = "email=" + values.email;
            document.cookie = "password=" + values.password;
          } else {
            document.cookie =
              "email=" +
              values.email +
              "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            document.cookie =
              "password=" +
              values.password +
              "=;  Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          }

          // localStorage.setItem('userType',res?.data?.id)
          if (res?.data?.is_verified === "Y" || res?.data?.user?.is_active) {
            localStorage.setItem("token", res?.data?.token);

            if (res?.data.user.role === 2) {
              if (res?.data.account_number === null) {
                setIsLoggedIn(false);
                // form.resetFields();
                navigate("/amb-bank-details");
              } else {
                setIsLoggedIn(true);
                // form.resetFields();
                navigate("/ambassodor/ambassodor-overview");
              }
            }
          } else if (
            // res?.data?.phone_number_verified ||
            !res?.data?.user?.is_active
          ) {
            if (res?.data.user.role === 2) {
              localStorage.setItem("userType", 2);
              sendOtp(res?.data?.user.email);
              // form.resetFields();
              navigate("/otp-verify", {
                state: {
                  id: {
                    email: res?.data?.user.email,
                    token: res?.data?.token,
                  },
                },
              });
            }
          }
        })
        .catch((e) => {
          setIsLoading(false);
          setError(e?.response?.data?.detail);
        });
    }
  };

  const defaultValues = {
    email: savedEmail,
    password: savedPassword,
  };

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  return (
    <AuthCard
      link={"/amb-create-account"}
      linkText={"Create an Account"}
      subtitle="Don’t have an account? click here to"
      isHide={true}
      loginPage={false}
    >
      <Head title="Sign In" />
      {
        <Form
          scrollToFirstError={true}
          form={form}
          onFinish={onFinish}
          id="amb-sign-in"
          layout="vertical"
          initialValues={defaultValues}
        >
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not a valid Email",
                validateTrigger: "onBlur",
              },
              { required: true, message: "", whitespace: true },

              ({ getFieldValue }) => ({
                validator(_) {
                  if (!getFieldValue("email")) {
                    return Promise.reject("Please input your Email Address");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              className={error?.email && "input-box-error"}
              placeholder="Email Address"
            />
          </Form.Item>
          {<div className="error-msg">{error?.email}</div>}
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password placeholder="Enter your password" />
          </Form.Item>

          <div className="error-msg d-flex">{error}</div>
          <Form.Item className="height-unset">
            <div className="row-between">
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
              <Link to="/forgot-password">
                <div className="login-form-forgot">Forgot password</div>
              </Link>
            </div>
          </Form.Item>
          <Button
            className="auth-btn login--btn"
            type="primary"
            onClick={() => form.submit()}
            loading={isLoading}
          >
            Log in
          </Button>
        </Form>
      }
    </AuthCard>
  );
};

export default Signin;
