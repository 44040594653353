import React, { useEffect, useState } from "react";
import AuthCard from "../../../Components/AuthCard";
import { useNavigate } from "react-router-dom";
import { Input, Select } from "antd";
import { Form, Button } from "antd";
import { SignIn, getRequest } from "../../../services";
import { ADD_USER_API, GET_PROFESSION } from "../../../services/ApiConstant";
import "./button.css";
import { convertPhoneNumber } from "../../../utils";
import { Head } from "../../../Components/seo";

const AmbassodorCreateAccount = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);

  // const [isModalOpen, setIsModalOpen] = useState(false);

  const [password, setPassword] = useState();

  const [error, setError] = useState();
  const [profession, setPrfession] = useState();

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  localStorage.setItem("userType", 2);
  useEffect(() => {
    getRequest(GET_PROFESSION).then((res) => {
      setPrfession(res.data);
    });
  }, []);

  const onFinish = (values) => {
    setIsLoading(true);
    values.role = 2;
    values.full_name = titleCase(values.full_name);
    values.phone_number = convertPhoneNumber(values.phone_number);

    SignIn(ADD_USER_API, values)
      .then((res) => {
        setError();
        if (res.status === 201) {
          if (values.remember === true) {
            document.cookie = "email=" + values.email;
            document.cookie = "password=" + values.password;
          } else {
            document.cookie =
              "email=" +
              values.email +
              "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            document.cookie =
              "password=" +
              values.password +
              "=;  Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          }
          setIsLoading(false);
          navigate("/otp-verify", {
            state: {
              id: { email: values.email, token: res?.data?.token },
            },
          });
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setError("");
  }, [password]);

  return (
    <AuthCard
      link="/"
      linkText={"Sign In"}
      subtitle="If you alredy have an account, click here to "
      page="Create Account"
      step="01"
      loginPage={false}
    >
      <Head title="Create Account" />
      <Form
        form={form}
        id="amb-create-account"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label="Fullname"
          name="full_name"
          rules={[
            {
              required: true,
              message: "Please input your Fullname",
              whitespace: true,
            },
          ]}
        >
          <Input disabled={isLoading} placeholder="Enter your fullname" />
        </Form.Item>
        {<div className="error-msg">{error?.full_name}</div>}

        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not a valid Email",
              validateTrigger: "onBlur",
            },
            { required: true, message: "", whitespace: true },

            ({ getFieldValue }) => ({
              validator(_) {
                if (!getFieldValue("email")) {
                  return Promise.reject("Please input your Email Address");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            className={error?.email && "input-box-error"}
            placeholder="Email Address"
          />
        </Form.Item>
        {<div className="error-msg">{error?.email}</div>}

        <Form.Item
          label="Phone Number"
          name={"phone_number"}
          rules={[
            {
              required: true,
              message: "Please enter your phone number",
              whitespace: true,
            },
            () => ({
              validator(_, value) {
                const validPhoneNumber = /^0[789][01]\d{8}$/.test(value);
                if (!validPhoneNumber) {
                  return Promise.reject("Please enter a valid phone number");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            className={error?.phone_number && "input-box-error"}
            placeholder="Phone Number"
          />
        </Form.Item>
        {<div className="error-msg">{error?.phone_number}</div>}

        <Form.Item
          label="What's your Profession"
          name="profession"
          rules={[
            {
              required: true,
              message: "Please input your Profession",
            },
          ]}
        >
          <Select>
            {profession?.map((item) => (
              <Select.Option disabled={isLoading} value={item.id} key={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password",
            },
            () => ({
              validator(_, value) {
                setPassword(value);
                if (value && value.length < 8) {
                  return Promise.reject(
                    "Password should be equal to or more than 8 characters",
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password
            disabled={isLoading}
            placeholder="Enter your password"
          />
        </Form.Item>
        {<div className="error-msg">{error?.password}</div>}

        <Form.Item
          label="Confirm Password"
          name="confirm_password"
          rules={[
            {
              required: true,
              message: "Please confirm your password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Your password and confirm password do not match"),
                );
              },
            }),
          ]}
        >
          <Input.Password disabled={isLoading} placeholder="Confirm password" />
        </Form.Item>
        {<div className="error-msg">{error?.confirm_password}</div>}
        <Button
          className="auth-btn login--btn"
          type="primary"
          loading={isLoading}
          onClick={() => form.submit()}
        >
          Create Account
        </Button>
      </Form>
    </AuthCard>
  );
};

export default AmbassodorCreateAccount;
