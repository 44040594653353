import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthCard from "../../../Components/AuthCard";
import { Button, Form, Input } from "antd";
import { REQUEST_OTP } from "../../../services/ApiConstant";
import { postRequest } from "../../../services";
import { Head } from "../../../Components/seo";

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [error, setError] = useState();

  const role = localStorage.getItem("userType");

  const onFinish = (values) => {
    console.log("values: ", { ...values });
    postRequest(`/email${REQUEST_OTP}`, { ...values, role }).then((res) => {
      if (res === undefined) {
        setError("account with this email doesn't exist");
      } else {
        navigate("/otp-verify", {
          state: {
            id: {
              email: values.email,
              forgetPassword: true,
            },
          },
        });
      }
    });
  };

  return (
    <AuthCard isHide={true}>
      <Head title="Reset Your Password" />
      <div className="reduced-t-gap">
        <p className="title">Reset Password</p>
        <p className="subtitle">
          Please enter the email address or phone number you used to create an
          account. We will send you a link to reset your password.
        </p>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not a valid Email",
                validateTrigger: "onBlur",
              },
              { required: true, message: "", whitespace: true },

              ({ getFieldValue }) => ({
                validator(_) {
                  if (!getFieldValue("email")) {
                    return Promise.reject("Please input your Email Address");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              className={error?.email && "input-box-error"}
              placeholder="Email Address"
            />
          </Form.Item>
          {<div className="error-msg">{error?.email}</div>}

          {<div className="error-msg">{error}</div>}
        </Form>

        <Button
          className="auth-btn login--btn"
          type="primary"
          onClick={() => form.submit()}
        >
          Send Link
        </Button>
      </div>
    </AuthCard>
  );
};

export default ForgotPassword;
