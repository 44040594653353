import { Helmet, HelmetData } from "react-helmet-async";

const helmetData = new HelmetData({});

export const Head = ({ title = "", description = "" }) => {
  return (
    <Helmet
      helmetData={helmetData}
      title={title ? `Ambassador | ${title}` : undefined}
      defaultTitle="Excelmind - Ambassador"
    >
      <meta name="description" content={description} />
    </Helmet>
  );
};
