import React, { useEffect, useState } from "react";
import AuthCard from "../../../Components/AuthCard";
import { useNavigate } from "react-router-dom";
import { Form, Button, Input } from "antd";
import { SignIn } from "../../../services";
import { ADD_USER_API } from "../../../services/ApiConstant";
import FacebookLogin from "react-facebook-login";
// css
import "./button.css";
//images
import facebookIcon from "../../../assets/images/logos_facebook.svg";
import { convertPhoneNumber } from "../../../utils";

const CreateAccount = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);
  const [emailValidation, setEmailValidation] = useState();
  const [password, setPassword] = useState();
  const [inputType, setInputType] = useState("email");
  const [error, setError] = useState();

  const queryParameters = new URLSearchParams(window.location.search);
  const refer_code = queryParameters.get("refer_code");

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  localStorage.setItem("userType", 1);

  const onFinish = (values) => {
    setIsLoading(true);
    if (refer_code !== null) {
      values.refer_code = refer_code;
      localStorage.setItem("userType", 1);
    }
    values.role = 1;
    values.full_name = titleCase(values.full_name);
    if (values.phone_number) {
      values.phone_number = convertPhoneNumber(values.phone_number);
    }
    console.log("values:", values);
    SignIn(ADD_USER_API, values)
      .then((res) => {
        setError();
        if (res.status === 201) {
          setIsLoading(false);
          navigate("/otp-verify", {
            state: {
              id: {
                email: values.email,
                token: res?.data?.token,
                phone_number: res?.data?.phone_number, // should => res?.data?.user.phone_number
              },
            },
          });
        }
      })
      .catch((e) => {
        setError(e.response.data);
        setError({ email: e.response.data.error[0] });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setError("");
  }, [emailValidation, password]);

  // facebook
  const responseFacebook = (response) => {
    console.log(response);
  };

  return (
    <AuthCard
      link="/"
      linkText={"Sign In"}
      subtitle="If you already have an account, click here to "
      page="Create Account"
      step="01"
      loginPage={false}
      logo
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Fullname"
          name="full_name"
          rules={[
            {
              required: true,
              message: "Please input your Fullname",
              whitespace: true,
            },
          ]}
        >
          <Input placeholder="Enter your fullname" />
        </Form.Item>
        {<div className="error-msg">{error?.full_name}</div>}

        {inputType === "email" && (
          <div className="input-box">
            <span
              onClick={() => setInputType("phone_number")}
              className="input-switch"
            >
              Use Phone number
            </span>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not a valid Email",
                  validateTrigger: "onBlur",
                },
                { required: true, message: "", whitespace: true },

                ({ getFieldValue }) => ({
                  validator(_, value) {
                    setEmailValidation(value);
                    if (!getFieldValue("email")) {
                      return Promise.reject("Please input your Email Address");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                className={error?.email && "input-box-error"}
                placeholder="Email Address"
              />
            </Form.Item>
            {<div className="error-msg">{error?.email}</div>}
          </div>
        )}
        {inputType === "phone_number" && (
          <div className="input-box">
            <span
              onClick={() => setInputType("email")}
              className="input-switch"
            >
              Use Email
            </span>
            <Form.Item
              label="Phone Number"
              name={"phone_number"}
              rules={[
                {
                  required: true,
                  message: "Please enter your phone number",
                  whitespace: true,
                },
                () => ({
                  validator(_, value) {
                    const validPhoneNumber = /^0[789][01]\d{8}$/.test(value);
                    if (!validPhoneNumber) {
                      return Promise.reject(
                        "Please enter a valid phone number",
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                className={error?.phone_number && "input-box-error"}
                placeholder="Phone Number"
              />
            </Form.Item>
            {<div className="error-msg">{error?.phone_number}</div>}
          </div>
        )}

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password",
            },
            ({ getFieldValue, validate }) => ({
              validator(_, value) {
                setPassword(value);
                if (value && value.length < 8) {
                  return Promise.reject(
                    "Password should be equal to or more than 8 characters",
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password placeholder="Enter your password" />
        </Form.Item>
        {<div className="error-msg">{error?.password}</div>}

        <Form.Item
          label="Confirm Password"
          name="confirm_password"
          rules={[
            {
              required: true,
              message: "Please confirm your password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Your password and confirm password do not match"),
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm password" />
        </Form.Item>
        {<div className="error-msg">{error?.confirm_password}</div>}
        <Button
          className="auth-btn login--btn"
          type="primary"
          loading={isLoading}
          onClick={() => form.submit()}
        >
          Create Account
        </Button>
      </Form>
      <div className="sign-in-opt-box">
        <div className="sign-in-continue">
          <div className="sign-in-line-box">
            <div className="sign-in-line" />
          </div>
          <div className="sign-in-text-box ">
            <span className="sign-in-text">Or</span>
          </div>
        </div>

        <div className="sign-in-btns">
          {/* <Button className="sign-in-btn" onClick={() => googleLogin()}>
            <img src={googleIcon} alt="google" className="sign-in-btn-icon" />
            Google
          </Button>          <GoogleLogin
            onSuccess={onGoogleSignInSuccess}
            onError={() => {
              console.log("Login Failed");
            }}
          />*/}

          <Button className="sign-in-btn">
            <img
              src={facebookIcon}
              alt="facebook"
              className="sign-in-btn-icon"
            />
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              fields="name,email,picture"
              callback={responseFacebook}
              textButton="Continue with Facebook"
              cssClass="facebook-button"
            />
          </Button>
        </div>
      </div>
      {/* <Link to="/exam-type"> */}

      {/* </Link> */}
    </AuthCard>
  );
};

export default CreateAccount;

