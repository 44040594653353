import {
  Switch,
  Form,
  message,
  Input,
  Select,
  Modal,
  Space,
  Skeleton,
} from "antd";
import { useEffect, useRef, useState } from "react";
import {
  getAuthRequest,
  getRequest,
  passwordUpdate,
  putAuthRequest,
} from "../../services";
import {
  GET_PROFESSION,
  PASSWORD_UPDATE,
  PROFILE_API,
} from "../../services/ApiConstant";
import { country } from "../../assets/common/country";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useNavigate } from "react-router-dom";
import countryCode3 from "../../assets/common/countryCode3";
import axios from "axios";
import { Head } from "../../Components/seo";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

const AmbassodorSettings = ({ step, page, fetchingProfileImage }) => {
  const [fullData, setFullData] = useState({});
  const [myData, setMyData] = useState();

  const [profession, SetProfession] = useState();
  const [status, setStatus] = useState(false);
  const [profile, setProfile] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedProfession, setSelectedProfession] = useState();
  const [error, setError] = useState();
  const [password, setPassword] = useState();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countryCallingCode, setCountryCallingCode] = useState("");
  const [phoneCode, setPhoneCode] = useState(countryCallingCode);
  const [userCountry, setUserCountry] = useState("");

  const [form] = Form.useForm();

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        // let data = response.data;
        setMyData(response.data);
      })
      .catch((error) => { });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  useEffect(() => {
    getAuthRequest(PROFILE_API, {}).then((res) => {
      setFullData(res.data);
      setStatus(true);
      selectCountryHandlerCode(res?.data?.country);
      fetchingProfileImage(res?.data?.profile_image);
    });
    getRequest(GET_PROFESSION).then((res) => {
      SetProfession(res.data);
    });
  }, [userCountry]);

  const onsubmit = (e) => {
    let profileInfo = {};
    let passwordManage = {};
    profileInfo.full_name = e.full_name;
    profileInfo.country = e.country;
    profileInfo.profession_id = e.profession_id;
    profileInfo.email = e.email;
    profileInfo.phone_number = e.phone_number;
    profileInfo.push_notification = e.push_notification;
    profileInfo.email_notification = e.email_notification;
    passwordManage.old_password = e.old_password;
    passwordManage.confirm_new_password = e.confirm_new_password;
    passwordManage.new_password = e.new_password;

    let formData = new FormData();

    profile !== undefined &&
      formData.append("profile_image", imgSrc, "sagar.png");

    formData.append("phone_country_code", countryCallingCode);

    Object.keys(profileInfo).map((k) => {
      return formData.append(k, profileInfo[k]);
    });
    if (!error) {
      putAuthRequest(PROFILE_API, formData, {
        Authorization: "Token " + localStorage.getItem("token"),
      })
        .then((res) => {
          passwordManage.confirm_new_password === undefined && ProfileDetails();
          fetchingProfileImage(res?.data?.profile_image);
          setFullData(res.data);
        })
        .catch((e) => {
          setError(e.response.data);
          ProfileDetailsBlock?.current?.scrollIntoView({ behavior: "smooth" });
        });
    }

    passwordManage.confirm_new_password !== undefined &&
      passwordUpdate(PASSWORD_UPDATE, passwordManage)
        .then((res) => {
          localStorage.setItem("token", res?.data?.token);
          PasswordUpdated();
          setError("");
          form.resetFields();
          // passwordManage={}
          // Password()
        })
        .catch((e) => {
          setError(e.response.data);
          PasswordUpdatedBlock?.current?.scrollIntoView({ behavior: "smooth" });
        });
  };

  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef();
  const imgRef = useRef();
  // const hiddenAnchorRef = useRef();
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(16 / 9);
  const navigate = useNavigate();
  const ProfileDetailsBlock = useRef();
  const PasswordUpdatedBlock = useRef();

  // const handleChange = (file) => {
  //   setIsModalOpen(true);
  //   setProfile(file.fileList[0].originFileObj);
  //
  //   setCrop(undefined);
  //   const reader = new FileReader();
  //   reader.addEventListener("load", () =>
  //     setImgSrc(reader.result?.toString() || ""),
  //   );
  //   reader.readAsDataURL(file.fileList[0]);
  // };

  function onClickFile(e) {
    e.target.value = null;
  }

  function onSelectFile(e) {
    setIsModalOpen(true);
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setProfile(reader.result?.toString() || ""),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
    canvasPreview(
      imgRef.current,
      previewCanvasRef.current,
      completedCrop,
      scale,
      rotate,
    );

    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist");
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);
      setProfile(blobUrlRef.current);
      setImgSrc(blob);
    });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setProfile(
      fullData?.profile_image == undefined
        ? require("../../assets/images/default-user-icon.png")
        : fullData?.profile_image,
    );
  };

  // const selectCountryHandlerData = (value) => {
  //     let callingCode = country.find((currCountry) => currCountry.code === value)?.callingCode
  //     setCountryCallingCode(callingCode)
  //     setPhoneCode(callingCode)
  // };

  const selectCountryHandler = (value) => {
    let callingCode = country.find(
      (currCountry) => currCountry.name === value,
    )?.callingCode;
    setCountryCallingCode(callingCode);
    setPhoneCode(callingCode);
    setSelectedCountry(
      country.find((currCountry) => currCountry.name === value)?.code,
    );
  };

  const selectCountryHandlerCode = (value) => {
    let callingCode = country.find(
      (currCountry) => currCountry.code === value,
    )?.callingCode;
    setCountryCallingCode(callingCode);
    setPhoneCode(callingCode);
    setSelectedCountry(
      country.find((currCountry) => currCountry.code === value)?.code,
    );
  };

  const ProfileDetails = () => {
    messageApi.open({
      type: "success",
      content: "Profile Details Updated",
    });
  };

  const PasswordUpdated = () => {
    messageApi.open({
      type: "success",
      content: "Password Updated",
    });
  };

  const selectProfessionHandler = (value) => {
    setSelectedProfession(value);
  };

  useEffect(() => {
    setError("");
  }, [password]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(() => {
      setUserCountry(countryCode3(myData.country_code_iso3));
      fullData?.phone_country_code == null
        ? setPhoneCode(
          country.find(
            (currCountry) =>
              currCountry.code === countryCode3(myData.country_code_iso3),
          )?.callingCode,
        )
        : setPhoneCode(fullData.phone_country_code);
      fullData?.phone_country_code == null
        ? setCountryCallingCode(
          country.find(
            (currCountry) =>
              currCountry.code === countryCode3(myData.country_code_iso3),
          )?.callingCode,
        )
        : setPhoneCode(fullData.phone_country_code);
    });
  }, [fullData, myData]);

  useEffect(() => {
    form.resetFields();
  }, [userCountry]);

  return (
    <>
      <Head title="Settings" />
      {contextHolder}
      <div className="settings-wrap">
        <div className="settings-row">
          <div className="left-content">
            <div className="ttl">
              <h3>BASIC INFO</h3>
              <div className="right-actions for-responsive">
                <div className="sideBtn">
                  <button
                    className="can-btn custom-btn"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    CANCEL
                  </button>
                  <button
                    className="sd-save-btn custom-btn"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </div>
            {status ? (
              <Form
                scrollToFirstError={true}
                onFinish={(e) => onsubmit(e)}
                form={form}
                initialValues={{
                  full_name: fullData?.full_name,
                  email: fullData?.user?.email,
                  push_notification: fullData?.push_notification,
                  email_notification: fullData?.email_notification,
                  phone_number: fullData?.phone_number,
                  profession_id: fullData?.profession?.id,
                  country: country.find(
                    (currCountry) =>
                      currCountry.code ===
                      (fullData.country == "" ? userCountry : fullData.country),
                  )?.name,
                }}
              >
                <div className="cus-ant-form">
                  <div className="row" ref={ProfileDetailsBlock}>
                    <div className="col-md-12 col-sm-12">
                      <div className="admin-user mb-4">
                        <Modal
                          title="Basic Modal"
                          open={isModalOpen}
                          onOk={handleOk}
                          onCancel={handleCancel}
                        >
                          {!!profile && (
                            <ReactCrop
                              crop={crop}
                              onChange={(_, percentCrop) =>
                                setCrop(percentCrop)
                              }
                              onComplete={(c) => setCompletedCrop(c)}
                              aspect={undefined}
                              minWidth={80}
                              minHeight={80}
                            >
                              <img
                                ref={imgRef}
                                alt="Crop me"
                                src={profile}
                                style={{
                                  transform: `scale(${scale}) rotate(${rotate}deg)`,
                                }}
                                onLoad={onImageLoad}
                              />
                            </ReactCrop>
                          )}
                          {!!completedCrop && (
                            <>
                              <div style={{ display: "none" }}>
                                <canvas
                                  ref={previewCanvasRef}
                                  style={{
                                    border: "1px solid black",
                                    objectFit: "contain",
                                    width: completedCrop.width,
                                    height: completedCrop.height,
                                  }}
                                />
                              </div>
                              <div></div>
                            </>
                          )}
                        </Modal>
                        <div className="user-tham">
                          <img
                            src={
                              profile === undefined
                                ? fullData?.profile_image === null
                                  ? require("../../assets/images/default-user-icon.png")
                                  : fullData?.profile_image
                                : profile
                            }
                            className=""
                            alt=""
                          />
                        </div>
                        <div className="upload-img-btn cusuploadbtn">
                          {/* <Form.Item label="" name="image">
                                        <Upload valuePropName='fileList' onChange={onSelectFile} maxCount={1}>
                                            <Button>Uplaod Image</Button>
                                        </Upload>
                                    </Form.Item> */}
                          <input
                            type="file"
                            id="cusUpload"
                            accept="image/*"
                            onClick={onClickFile}
                            onChange={onSelectFile}
                          />
                          <label htmlFor="cusUpload">Upload Image</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-sm-12">
                      <div className="form-group">
                        <Form.Item
                          label="Full Name"
                          name="full_name"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Your Full Name",
                              whitespace: true,
                            },
                          ]}
                        >
                          <Input placeholder="Please enter the full name" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <Form.Item
                          label="Email"
                          name="email"
                          rules={[
                            {
                              type: "email",
                              message: "The input is not vaild E-email!",
                            },
                            {
                              required: true,
                              message: "Please input your Email",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Please enter your email"
                            disabled
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <Form.Item
                          label="Phone  number"
                          name="phone_number"
                          rules={[
                            {
                              required: true,
                              message: "Please input your Phone Number",
                              whitespace: true,
                            },
                            {
                              max: 11,
                              message:
                                "Phone Number should be less than or equal to 11 Digits",
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            addonBefore={<div>{phoneCode}</div>}
                            placeholder="Please enter your phone number"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <Form.Item
                          label="Profession"
                          name="profession_id"
                          rules={[
                            {
                              required: true,
                              message: "Please select your profession",
                            },
                          ]}
                        >
                          <Select
                            name="profession_id"
                            onChange={selectProfessionHandler}
                            value={selectedProfession}
                          >
                            {profession?.map((item) => (
                              <Select.Option value={item.id}>
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <Form.Item
                          label="Country"
                          name="country"
                          rules={[
                            {
                              required: true,
                              message: "Please input your Country Name",
                              whitespace: true,
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            onChange={selectCountryHandler}
                            value={selectedCountry}
                          >
                            {country.map((i) => (
                              <Select.Option value={i.name}>
                                {i.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className="ttl">
                    <h3>PASSWORD SETTINGS</h3>
                  </div>
                  <div className="formWrap" ref={PasswordUpdatedBlock}>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          {/* <label for="">OLD PASSWORD</label> */}
                          {/* <input type="text" className="form-control-field" /> */}
                          <Form.Item
                            label="Old Password"
                            name="old_password"
                            rules={[
                              {
                                message: "Please input your password!",
                              },
                            ]}
                          >
                            <Input.Password placeholder="Please enter your old password" />
                          </Form.Item>
                          {
                            <div className="error-msg">
                              {error?.old_password}
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12"></div>
                      <div className="col-md-6 col-sm-12">
                        {/* <div className="form-group">
                                                <label for="">NEW PASSWORD</label>
                                                <input type="text" className="form-control-field" />
                                            </div> */}
                        <div className="form-group">
                          <Form.Item
                            label="New Password"
                            name="new_password"
                            rules={[
                              {
                                message: "Please input your password!",
                              },
                              ({ getFieldValue, validate }) => ({
                                validator(_, value) {
                                  setPassword(value);

                                  if (value && value.length < 8) {
                                    return Promise.reject(
                                      "Password should be equal to or more than 8 characters",
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <Input.Password placeholder="Please enter your new password" />
                          </Form.Item>
                          {
                            <div className="error-msg">
                              {error?.new_password}
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12"></div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <Form.Item
                            label="Confirm Password"
                            name="confirm_new_password"
                            dependencies={["new_password"]}
                            rules={[
                              {
                                message: "Please confirm your password",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    getFieldValue("new_password") === value
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      "Your password and confirm password do not match",
                                    ),
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input.Password placeholder="Confirm Password" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12"></div>
                    </div>
                  </div>

                  <div className="ttl">
                    <h3>NOTIFICATION</h3>
                  </div>
                  <div className="switchPanel">
                    <div className="text">
                      <p>Push Notifications</p>
                    </div>
                    <div className="switch-box">
                      <Form.Item
                        label=""
                        valuePropName="checked"
                        name="push_notification"
                      >
                        <Switch />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="switchPanel">
                    <div className="text">
                      <p>Email Notifications</p>
                    </div>
                    <div className="switch-box">
                      <Form.Item
                        label=""
                        valuePropName="checked"
                        name="email_notification"
                      >
                        <Switch />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </Form>
            ) : (
              <div className="skeleton-loading">
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div className="row">
                      <div
                        className="col-12 col-md-1"
                        style={{ marginBottom: "2%" }}
                      >
                        <Space>
                          <Skeleton.Image active={true} />
                        </Space>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-12">
                    <div className="row">
                      <div className="col-12 col-md-12">
                        <div className="form-group">
                          <div className="skeleton-form-control"></div>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <div className="skeleton-form-control"></div>
                    </div>{" "}
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <div className="skeleton-form-control"></div>
                    </div>{" "}
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <div className="skeleton-form-control"></div>
                    </div>{" "}
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <div className="skeleton-form-control"></div>
                    </div>{" "}
                  </div>
                  <div className="ttl">
                    <h3>PASSWORD SETTINGS</h3>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <div className="skeleton-form-control"></div>
                    </div>{" "}
                  </div>

                  <div className="col-12 col-md-12">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <div className="skeleton-form-control"></div>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <div className="skeleton-form-control"></div>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="ttl">
                    <h3>NOTIFICATION</h3>
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="row">
                      <div className="col-4 col-md-3 ml-auto">
                        <div className="form-group">
                          <div className="skeleton-form-control"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="right-actions for-desktop">
            <div className="sideBtn">
              <button
                className="can-btn custom-btn"
                onClick={() => {
                  navigate("/");
                }}
              >
                CANCEL
              </button>
              <button
                className="sd-save-btn custom-btn"
                onClick={() => {
                  form.submit();
                }}
              >
                SAVE
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AmbassodorSettings;
