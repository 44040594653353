import { Button, Form, Input, Modal, Select, message } from "antd";
import { useEffect, useState } from "react";
import { Head } from "../../Components/seo";
import {
  Login,
  getAuthRequest,
  getRequest,
  getUserData,
  postRequest,
} from "../../services";
import {
  AMBASSADOR_ALL_WITHDRAWLS,
  GET_BANKS,
  LOGIN_API,
  POST_BANKUPDATE,
  PROFILE_API,
} from "../../services/ApiConstant";

const AmbassodorPayment = ({ step, page, bankDetails }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const role = localStorage.getItem("userType");
  const [changeAccountNo, setChangeAccountNo] = useState(false);

  const showChangeAccount = () => {
    setChangeAccountNo(true);
  };
  const handleOkChangeAccount = () => {
    setChangeAccountNo(false);
  };
  const handleCancelChangeAccount = () => {
    setChangeAccountNo(false);
    form.resetFields();
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [form] = Form.useForm();
  const [banks, setBanks] = useState([]);
  const [profile, setProfile] = useState();
  const [error, setError] = useState();
  //cashout flow

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [allWithdrawlsAccount, setAllWithdrawlsAccount] = useState([]);
  const [allDetails, setAllDetails] = useState();
  const [perPersonDetails, SetPerPersonDetails] = useState({});
  // const [allBankDetails, setAllBankDetails] = useState({
  //   bank: "",
  //   account_number: "",
  //   account_name: "",
  //   password: "",
  // });
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [updatePage, setUpdatePage] = useState(true);
  const currentYear = new Date().getFullYear();
  const year = Array.from({ length: 5 }, (_, index) =>
    (currentYear - index).toString(),
  );
  const [filterMonth, setFilterMonth] = useState(1);
  const [filterYear, setFilterYear] = useState(year[0]);

  useEffect(() => {
    setLimit(limit <= 0 ? 10 : limit);
    getUserData(
      `${AMBASSADOR_ALL_WITHDRAWLS}/?limit=${limit}&offset=${offset}&month=${filterMonth}&year=${filterYear}`,
    ).then((res) => {
      setAllDetails(res.data);
      setAllWithdrawlsAccount(res.data.results);
      setTotal(res.data.count);
    });
  }, [offset, limit, filterMonth, filterYear]);

  const [pages, setPages] = useState([]);

  useEffect(() => {
    setPages([]);
    let i = 0;
    i = Math.ceil(total / limit);

    for (let index = 0; index < i; index++) {
      setPages((pages) => [...pages, index * limit]);
    }
  }, [total, limit]);

  const NextPreButton = (url) => {
    if (url != null) {
      let loc = new URL(url);
      let search = new URLSearchParams(loc.search);
      setLimit(search.get("limit"));
      search.get("offset") == null
        ? setOffset(0)
        : setOffset(search.get("offset"));
    }
  };

  const changingLimit = (e) => {
    setOffset(0);
    setLimit(e.target.value);
  };

  //perPersoneAccountDetails

  const getPerPersonWithDrawlsDetails = (id) => {
    showModal();
    getUserData(`${AMBASSADOR_ALL_WITHDRAWLS}/${id}`).then((res) => {
      SetPerPersonDetails(res.data);
    });
  };
  //bank deatails
  useEffect(() => {
    setError("  ");
    getRequest(GET_BANKS).then((res) => {
      setBanks(res?.data);
    });
    getAuthRequest(PROFILE_API, {}).then((res) => {
      setProfile(res.data);
      form.resetFields();
    });
  }, [changeAccountNo]);

  // useEffect(()=>{
  //     setError("  ")
  //     getRequest(GET_BANKS).then((res) => {
  //         setBanks(res?.data);
  //     })
  //     getAuthRequest(PROFILE_API, {}).then((res) => {
  //         setProfile(res.data)
  //     }).catch((e) => {
  //     })
  // },[updatePage])

  const onFinish = (values) => {
    let passwordCheck = {};
    passwordCheck.email = profile.user.email;
    passwordCheck.password = values.password;
    passwordCheck.role = role;
    let bank = {};
    bank.bank = values.bank;
    bank.account_number = values.account_number;
    bank.account_name = values.account_name;

    Login(LOGIN_API, passwordCheck)
      .then((res) => {
        localStorage.setItem("token", res?.data?.token);
        postRequest(POST_BANKUPDATE, bank).then(() => {
          setUpdatePage(!updatePage);
          handleOkChangeAccount();
          info();
          // navigate('/AmbassodorSignin')
        });
      })
      .catch((e) => {
        setError(e.response.data.detail);
      });
  };

  const info = () => {
    // messageApi.info('Copied');
    messageApi.open({
      type: "success",
      content: "Bank data saved successfully",
    });
  };

  const GetDate = (date) => {
    const gotDate = new Date(date);
    const convertedDate = new Date(gotDate);
    return (
      <p>
        {convertedDate.toLocaleDateString()}{" "}
        {convertedDate.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}{" "}
      </p>
    );
  };

  const d = new Date();
  const currentMonth = d.getMonth();

  useEffect(() => {
    setFilterMonth(currentMonth + 1);
  }, []);

  return (
    <>
      <Head title="Payment" />
      {contextHolder}
      <div className="ambcardpanel">
        <Modal
          title="Transaction Info"
          className="transaction-info-modal"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[]}
        >
          <div className="transaction-infowrap">
            <ul>
              <li>
                <span>Account Name:</span>{" "}
                <strong>{perPersonDetails.account_name}</strong>
              </li>
              <li>
                <span>Account NUMBER:</span>{" "}
                <strong>{perPersonDetails.account_number}</strong>
              </li>
              <li>
                <span>Bank Name:</span> <strong>{perPersonDetails.bank}</strong>
              </li>
              <li>
                <span>CASHOUT AMOUNT:</span> <strong>₦ ?</strong>
              </li>
              <li>
                <span>BALANCE:</span>{" "}
                <strong>₦ {perPersonDetails.amount}</strong>
              </li>
              <li>
                <span>DATE:</span>{" "}
                <strong>{GetDate(perPersonDetails.updated_at)}</strong>
              </li>
              <li>
                <span>STATUS:</span> <strong>{perPersonDetails.status}</strong>
              </li>
            </ul>
          </div>
        </Modal>

        <Modal
          title=""
          open={changeAccountNo}
          onOk={handleOkChangeAccount}
          getContainer={false}
          onCancel={handleCancelChangeAccount}
          footer={[
            <Button
              className="auth-btn"
              type="primary"
              onClick={() => form.submit()}
            >
              {" "}
              Change{" "}
            </Button>,
          ]}
        >
          <div className="change-account-modal">
            <div className="change-account-modal-ttl">
              <h3>Change Account Number</h3>
            </div>
            <div className="cus-ant-form">
              <Form
                onFinish={onFinish}
                form={form}
                initialValues={{
                  bank: profile?.bank?.id,
                  account_number: profile?.account_number,
                  account_name: profile?.account_name,
                  password: "",
                }}
              >
                <Form.Item
                  label="Bank Name"
                  name="bank"
                  rules={[{ required: true, message: "Please select bank" }]}
                >
                  <Select>
                    {banks?.map((item) => (
                      <Select.Option value={item.id}>
                        {item.bank_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Account Number"
                  name="account_number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your account number",
                      whitespace: true,
                    },
                    {
                      max: 11,
                      message:
                        "Account Number should be less than or equal to 11 Digits",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    className="highlight-placeholder"
                    placeholder="Enter account number here"
                  />
                </Form.Item>
                <Form.Item
                  label="Account Name"
                  name="account_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your account name",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    className="highlight-placeholder"
                    placeholder="Enter account name here"
                  />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password
                    className="highlight-placeholder"
                    placeholder="Enter Password here"
                  />
                </Form.Item>
                {<div className="error-msg">{error}</div>}
              </Form>
            </div>
          </div>
        </Modal>

        <div className="row align-items-end">
          <div className="col-lg-4 col-md-12 col-ms-12">
            <div className="box autoHeight">
              <div className="zenith-box">
                <div className="tham">
                  <img src={profile?.bank.bank_image} />
                </div>
                <div className="info">
                  <strong>{profile?.account_number}</strong>
                  <p>{profile?.account_name}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-ms-12">
            <div className="accountbtnbox">
              <button
                className="btn"
                onClick={() => {
                  showChangeAccount();
                }}
              >
                Click Here To Change Account Number
              </button>
            </div>
          </div>
          <div className="col-md-4 col-ms-12"></div>
        </div>
      </div>
      <div className="commtablearea">
        <div className="ttl">
          <h3>CASHOUT HISTORY</h3>
          <div>
            <select
              onChange={(e) =>
                setFilterMonth(month.indexOf(e.target.value) + 1)
              }
            >
              {month.map((i) => (
                <option value={i} selected={i == month[currentMonth]}>
                  {i}
                </option>
              ))}
            </select>
            <select
              onChange={(e) => setFilterYear(e.target.value)}
              style={{ marginLeft: "10px" }}
            >
              {year.map((i) => (
                <option value={i}>{i}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="tablewrap">
          <table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>NAME</th>
                <th>ACC. NO</th>
                <th>AMOUNT</th>
                <th>DATE/TIME</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {allWithdrawlsAccount.length == 0 ? (
                <tr>
                  <td colSpan={6}>
                    <div className="no-data-show-msg-sec">
                      <div className="icon-container">
                        <img src="/images/no-data-show.svg" alt=""></img>
                      </div>
                      <div className="text-container">No data to show</div>
                    </div>
                  </td>
                </tr>
              ) : (
                allWithdrawlsAccount?.map((item, index) => (
                  <tr
                    onClick={() => {
                      getPerPersonWithDrawlsDetails(item.id);
                    }}
                  >
                    <td th-name="S/N :">{index + 1 + Number(offset)}.</td>
                    <td th-name="NAME :">{item.account_name}</td>
                    <td th-name="ACC. NO :">{item.account_number}</td>
                    <td th-name="AMOUNT :">₦ {item.amount}</td>
                    <td th-name="DATE/TIME :">{GetDate(item.updated_at)}</td>
                    <td th-name="STATUS :">
                      <span
                        className={
                          item.status == "approved"
                            ? "status-approve"
                            : item.status == "pending"
                              ? "status-pending"
                              : "status-declined"
                        }
                      >
                        {item.status}
                      </span>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        {allWithdrawlsAccount?.length == 0 ? (
          ""
        ) : (
          <div className="tablepagi">
            {/* <ul>
                        <li className="nxp-btn"><a href="#">Previous</a></li>
                        <li className="active"><a href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li>...</li>
                        <li><a href="#">10</a></li>
                        <li className="nxp-btn"><a href="#">Next</a></li>
                    </ul> */}
            <ul>
              <li className="nxp-btn">
                <a onClick={() => NextPreButton(allDetails.previous)}>
                  Previous
                </a>
              </li>
              {pages.map((no, index) => (
                <li className={no == offset && "page-active"}>
                  <a onClick={() => setOffset(no)}>{index + 1}</a>
                </li>
              ))}
              <select
                className="pageNumbers"
                onChange={(e) => {
                  changingLimit(e);
                }}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>

              <li className="nxp-btn">
                <a onClick={() => NextPreButton(allDetails.next)}>Next</a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default AmbassodorPayment;
